import { Component, Input } from '@angular/core';
import { SENDER } from '../../types/card-sender';

@Component({
  selector: 'app-recipient-details',
  templateUrl: './recipient-details.component.html',
  styleUrls: ['./recipient-details.component.scss']
})
export class RecipientDetailsComponent {
  @Input() tracking!: SENDER;
}
