<div class="parcel-not-found">
    <div class="not-found-icon">
        <img src="../../../../assets/image.png" class="error-icon" />
    </div>
    <div class="header">
        <p class="header-text" *ngIf="!query">No tracking number provided</p>
        <p class="header-text" *ngIf="query">No parcel found</p>
    </div>
    <div class="description">
        <p *ngIf="!query">Please provide a valid search query</p>
        <p *ngIf="query">We couldn’t find any parcels matching this tracking number.</p>
        <p class="darker" *ngIf="query">Try another tracking number.</p>
    </div>
</div>