import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-parcel-status-icon',
  templateUrl: './parcel-status-icon.component.html',
  styleUrls: ['./parcel-status-icon.component.scss']
})
export class ParcelStatusIconComponent {
  @Input() parcelStatusIdNo!: number;
}
