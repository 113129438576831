<div class="parcel-icon-container">
  <!-- <mat-icon
        *ngIf="parcelStatusIdNo === 3 || parcelStatusIdNo === 16 || parcelStatusIdNo === 14 || parcelStatusIdNo === 15 || parcelStatusIdNo === 8"
        class="parcel-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="check">
    </mat-icon>
    <mat-icon *ngIf="parcelStatusIdNo === 1 || parcelStatusIdNo === 19" class="parcel-icon" aria-hidden="false"
        aria-label="Example home icon" fontIcon="hourglass_top">
    </mat-icon>
    <mat-icon *ngIf="parcelStatusIdNo === 2 || parcelStatusIdNo === 4" class="parcel-icon" aria-hidden="false"
        aria-label="Example home icon" fontIcon="fire_truck">
    </mat-icon>
    <mat-icon *ngIf="parcelStatusIdNo === 5" class="parcel-icon" aria-hidden="false" aria-label="Example home icon"
        fontIcon="shopping_bag">
    </mat-icon>
    <mat-icon
        *ngIf="parcelStatusIdNo === 6 || parcelStatusIdNo === 7 || parcelStatusIdNo === 9 || parcelStatusIdNo === 12 || parcelStatusIdNo === 17 || parcelStatusIdNo === 18 || parcelStatusIdNo === 20"
        class="parcel-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="search">
    </mat-icon> -->
  <div class="parcel-icon-temp"></div>
</div>
