import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.scss'],
})
export class TrackingDetailsComponent {
  @Input() tracking!: {
    eventTypeIdNo: number;
    eventTypeDesc: string;
    eventNodeCode: string;
    eventNodeName: string;
    eventDatetime: string;
    eventReference: string;
    eventMessage: string;
    eventUsername: string;
    eventDeviceIdNo: null;
  }[];
}
