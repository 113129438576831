import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-parcel-status',
  templateUrl: './parcel-status.component.html',
  styleUrls: ['./parcel-status.component.scss']
})
export class ParcelStatusComponent {
  @Input() parcelStatus!: string;
  @Input() parcelStatusIdNo!: number;
}
