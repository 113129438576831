import { Component, Input } from '@angular/core';
import { TRACKING_RESPONSE } from '../../types/tracking-response';

@Component({
  selector: 'app-tracking-screen',
  templateUrl: './tracking-screen.component.html',
  styleUrls: ['./tracking-screen.component.scss']
})
export class TrackingScreenComponent {
  _tracking!: TRACKING_RESPONSE;
  _loading!: boolean;
  _error!: boolean;
  _query!: string;
  @Input() set loading(isLoading: boolean) {
    this._loading = isLoading;
  }
  @Input() set tracking(tracking: TRACKING_RESPONSE) {
    this._tracking = tracking;
  }
  @Input() set notError(error: boolean) {
    this._error = error;
  }
  @Input() set query(query: string) {
    this._query = query;
  }
}
