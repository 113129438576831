<div class="card">
    <div class="card-header">{{ tracking.cardHeader }}</div>
    <div class="card-info">
        <div class="sub-header">{{ tracking.dateDropOff }}</div>
        <p class="card-info-text">{{ tracking.dateDropOffText }}</p>
    </div>
    <div class="card-info">
        <div class="sub-header">{{ tracking.sendFrom }}</div>
        <p class="card-info-text">{{ tracking.sendFromText }}</p>
    </div>
    <div class="card-info">
        <div class="sub-header">{{ tracking.senderName }}</div>
        <p class="card-info-text">{{ tracking.senderNameText }}</p>
    </div>
</div>