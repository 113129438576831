import { Component, Input, OnInit } from '@angular/core';
import { PARCEL_EVENTS } from '../../types/tracking-response';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent {
  @Input() parcelEvents!: PARCEL_EVENTS;
}
