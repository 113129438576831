<div class="right-panel">
    <div class="vertical-frame">
      <div
        *ngFor="let item of parcelEvents; index as r"
        class="panel-container"
      >
        <div class="circle checked">
          <mat-icon
            class="check-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="check"
          >
          </mat-icon>
        </div>
        <div
          *ngIf="parcelEvents.length - 1 !== r"
          class="dotted-container"
        >
          <div class="dots"></div>
        </div>
      </div>
    </div>
    <div class="square-frame">
      <app-tracking-details
        [tracking]="parcelEvents"
      ></app-tracking-details>
    </div>
  </div>