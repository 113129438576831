import { Component, Input } from '@angular/core';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { TRACK } from '../../types/tracking-response';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent {
  sender = false;
  recipient = false;
  @Input() tracking!: TRACK;
  formatDateTime(date: string) {
    const parsed = parseISO(date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return formatInTimeZone(parsed, timeZone, 'dd MMM yyyy HH[H]mm').replace(
      /\[[^\[\]]*\]/g,
      'H'
    );
  }
  expandSender() {
    this.sender = !this.sender;
  }
  expandRecipient() {
    this.recipient = !this.recipient
  }
}
